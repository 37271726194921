import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Bradcam from "../components/bradcam"

const AboutUsPage = () => (
  <Layout>
    <SEO title="About Us" />
    <Bradcam siteTitle="About Us"></Bradcam>

    <div class="about_mission">
        <div class="container">
            <div class="row align-items-center">
                
                <div class="col-xl-6 col-md-6">
                    <div class="about_text">
                        <h4>云尚地产</h4>
                        <p class="subtitle">住宅买卖，商业地产，全美置业，房屋托管​</p>
                        <p>云尚地产（Cloud Up Realty）是首次购房者的贴心顾问，为首次购房者提供免费咨询，细致讲解购房注意事项。</p>
                        <p>云尚地产是投资者的长期合作伙伴，提供完善的房屋托管，让投资者高枕无忧。</p>
                        <p>云尚地产全方位房地产经纪服务包括：</p>
                        <ul>
                            <li>大芝加哥地区住宅买卖</li>
                            <li>协助投资者外州购房，全美置业</li>
                            <li>商业地产交易，办公室租赁</li>
                            <li>投资房托管，出租</li>
                            <li>首次购房专业咨询，免费评估</li>
                        </ul>
                    </div>
                </div>
                <div class="col-xl-6 col-md-6">
                    <div class="about_thumb">
                        <img src="/img/about/about-2.png" alt="" />
                    </div>
                </div>
            </div>
        </div>
    </div>



        <div class="team_area">
            <div class="container">
                    <div class="row">
                            <div class="col-xl-12">
                                <div class="section_title mb-40">
                                    <h3>我们的团队</h3>
                                </div>
                            </div>
                        </div>
                        <div >
                            <div >

                              <div class="team-flex-container">
                                <div class="single_team">
                                    <div class="team_thumb">
                                        <img src="/img/team/pengfei.jpg" alt="" />
                                        {/* <div class="social_link">
                                                <ul>
                                                    <li><a href="#">
                                                            <i class="fa fa-facebook"></i>
                                                        </a>
                                                    </li>
                                                    <li><a href="#">
                                                            <i class="fa fa-twitter"></i>
                                                        </a>
                                                    </li>
                                                    <li><a href="#">
                                                            <i class="fa fa-instagram"></i>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div> */}
                                    </div>
                                    <div class="team_info text-center">
                                        <h3>Pengfei Zhang</h3>
                                        <p>Business Owner</p>
                                    </div>
                                </div>
                                <div class="team-desc">
<p>Pengfei Zhang 为云尚地产的创始人，拥有丰富从业经验，了解市场，洞悉行情，是芝加哥郊区房产买卖的专家，对芝加哥郊区学区房了如指掌。</p>
<p>作为一个认真、热心、积极、感恩的房地产经纪，Pengfei善于倾听客户需求，提供专业的咨询与建议，无论是买房还是卖房，尽最大可能为客户带来最大的价值。</p>
<p>Pengfei从业多年，90%的客户来自推荐介绍。以热情、专业的服务与客户建立长期的信任与合作共赢，是Pengfei的执业理念。是客户值得信赖的购房伙伴。Pengfei的座右铭是：“您找我买房，我自信将来您卖房也一定会找我！”
对待首次购房客户，Pengfei更加认真和耐心。Pengfei为首次购房者提供免费的咨询，讲解购房流程的方方面面，需要注意的点点滴滴，根据需求精准定位理想房产。</p>
<p>在房产销售方面，Pengfei了解不同地区的市场状态，针对性地采取不同的定价策略，帮客户以最好的价格，最快的速度，达成售房交易。</p>
<p>Pengfei Zhang 拥有全美国地产协会认证买家代理人资格Accredited Buyer's Representative(ABR)，2021年提名《Top Agent Magazine》Top Producer。Pengfei还拥有地产管理经纪人执照，IIT商学院市场营硕士学位。</p>
                                </div>
                              </div>
                            </div>
                        
                        </div>
                      
            </div>
        </div>


  </Layout>
)

export default AboutUsPage
